<template>
    <v-treeview
        v-model="selected"
        :items="localCustomers"
        :return-object="true"
        :disable-per-node="true"
        selected-color="primary"
        item-disabled="locked"
        open-on-click
        selectable
        indeterminate-icon="mdi-bookmark-minus">
        <template v-slot:label="{item}">
            <div class="treeview-item" :load-children="loadChildren(item)">{{ item.name }}</div>
        </template>
        <template v-slot:prepend="{item}">
            <v-progress-circular
                v-if="item.loading"
                :size="20"
                :width="2"
                indeterminate
                color="primary"
            ></v-progress-circular>
        </template>
    </v-treeview>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { ICONS } from '@/constants/icons';
import { STEP_ELEMENT_TYPE_ENUM } from '@/constants/cyclicRevision';
import { mapFields } from 'vuex-map-fields';

export default {
    name: 'PointsTree',
    props: {
        customers: Array,
        loadedAllCustomers: Boolean
    },
    data: () => ({
        selected: [],
        selectedIcon: ICONS.SINGLE_DOWN,
        localCustomers: []
    }),
    watch: {
        selected: {
            handler (val, oldVal) {
                if (JSON.stringify(val) === JSON.stringify(oldVal) && val.length !== 0) return; // when changing selected from outside, watcher is triggered twice - this is a workaround

                if (this.activeSteps) {
                    const uniqueActivePoints = new Set(this.activeSteps.filter(step => step.point.id).map(step => step.point.id));

                    if (val.length === uniqueActivePoints.size) {
                        this.$store.dispatch('cyclicRevisions/setAllActivePointsSelected', true);
                    }
                }

                if (val.length > 0) {
                    val.filter(item => item.elementType === 'site').forEach(item => {
                        this.loadPoints(item);
                    });
                    val = val.filter((item) => item.elementType === STEP_ELEMENT_TYPE_ENUM.POINT);
                }
                this.dispatchPointsActions(val);
            }
        },
        customers: {
            handler (val) {
                this.localCustomers = [...val];
            }
        },
        loadedAllCustomers: {
            handler (val) {
                if (val) {
                    this.selectTrackPoints();
                }
            }
        }
    },
    computed: {
        ...mapGetters('customers', [
            'listingCustomers'
        ]),
        ...mapState('customers', [
            'sitesPerCustomers', 'locationsPerSite'
        ]),
        ...mapState('manageTrack', [
            'trackData'
        ]),
        ...mapFields('manageTrack', [
            'trackData.sequence.activeSteps'
        ])
    },
    methods: {
        async loadChildren (item) {
            switch (item.elementType) {
            case 'customer':
                await this.loadSites(item);
                break;
            case 'site':
                await this.loadPoints(item);
                break;
            default:
            }
        },
        async loadSites (customer) {
            if (!customer.children) {
                this.$set(customer, 'children', []);
                let i = 1;
                let break_loop = false;

                while (!break_loop) {
                    await this.$store.dispatch('customers/getSitesPerCustomer', { id: customer.id, pageNumber: i }).then(() => {
                        const sites = this.sitesPerCustomers.map(item => ({
                            id: `${item.id}_site`,
                            name: item.name,
                            elementType: 'site',
                            pointsCount: item.points_count
                        }));
                        if (sites.length > 0) {
                            customer.children.push(...sites);
                            if (!sites.find(site => site.pointsCount > 0)) { // disable customer if it has no points
                                customer.locked = true;
                            }
                        } else {
                            break_loop = true;
                            if (i === 1) {
                                customer.locked = true;
                                this.localCustomers.splice(this.localCustomers.indexOf(customer), 1, customer);
                                customer.children = [];
                            }
                        };
                    });
                    i += 1;
                }
            }
        },
        async loadPoints (site) {
            if (!site.children) {
                site.loading = true;
                this.$set(site, 'children', []);
                let i = 1;
                let break_loop = false;

                while (!break_loop) {
                    const siteId = site.id.split('_')[0];
                    await this.$store.dispatch('customers/getLocationsPerSite', { id: siteId, pageNumber: i }).then(() => {
                        site.loading = false;
                        const points = this.locationsPerSite.map(item => ({
                            id: `${item.id}_point`,
                            name: item.name,
                            elementType: STEP_ELEMENT_TYPE_ENUM.POINT
                        }));
                        if (points.length > 0) {
                            site.children.push(...points);
                        } else {
                            break_loop = true;
                            if (i === 1) {
                                site.children = [];
                                site.locked = true;
                            }
                        }
                    });
                    i += 1;
                }
            }
        },
        removeSelected (item) {
            this.selected = this.selected.filter(i => i.id !== item.id);
        },
        async dispatchPointsActions (points) {
            for (const point of points) {
                if (point.elementType === STEP_ELEMENT_TYPE_ENUM.POINT) {
                    const pointId = point.id.split('_')[0];
                    await this.$store.dispatch('manageTrack/getStepInspectionTemplates', pointId);
                }
            }
            await this.$store.dispatch('cyclicRevisions/setSelectedTracksPoints', points);
        },
        selectTrackPoints () {
            if (this.trackData?.sequence?.activeSteps) {
                this.getPoints().then(points => {
                    this.selected = points;
                });
            }
        },
        async getPoints () {
            const customers = this.customers.filter(customer => this.trackData.sequence.activeSteps.find(step => step.point.clientId === customer.id));

            const locations = await Promise.all(customers.map(async customer => {
                const localLocations = [];
                await this.loadSites(customer).then(() => {
                    const filteredLocations = customer?.children.filter(location => this.trackData.sequence.activeSteps.find(step => step.point.locationId === location.id));
                    localLocations.push(...filteredLocations);
                });
                return localLocations;
            })).then(locations => locations.flat());

            return await Promise.all(locations.map(async location => {
                const localPoints = [];
                await this.loadPoints(location).then(() => {
                    const filteredPoints = location?.children.filter(point => this.trackData.sequence.activeSteps.find(step => step.point.id === point.id)) || [];
                    localPoints.push(...filteredPoints);
                });
                return localPoints;
            })).then(points => points.flat());
        }
    }
};
</script>

<style scoped>
.treeview-item {
    max-width: 100px;
}
</style>
