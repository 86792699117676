import { render, staticRenderFns } from "./PointsTree.vue?vue&type=template&id=b6a77340&scoped=true&"
import script from "./PointsTree.vue?vue&type=script&lang=js&"
export * from "./PointsTree.vue?vue&type=script&lang=js&"
import style0 from "./PointsTree.vue?vue&type=style&index=0&id=b6a77340&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6a77340",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VProgressCircular,VTreeview})
